import { useEffect, useState } from 'react';
import React from 'react';
import { useLocation } from '@reach/router';
import { ONE_MINUTE } from './time';
import { setInterval } from 'timers';

type TimeoutReturnType = ReturnType<typeof setTimeout> | undefined;

type Props = {
  timeMs?: number;
  action: () => void | Promise<void>;
  active?: boolean;
};

export const useInterval = ({
  timeMs = ONE_MINUTE * 2,
  action = async () => {},
  active = true,
}: Props) => {
  const [timeToNextAction, setTimeToNextAction] = useState(timeMs);
  const [runningAction, setRunningAction] = useState(false);
  const [timeoutId, setTimeoutId] = useState<TimeoutReturnType>(undefined);
  const [intervalId, setIntervalId] = useState<TimeoutReturnType>(undefined);
  const [trigger, setTrigger] = useState(false);

  const cleanup = () => {
    clearTimeout(timeoutId);
    clearInterval(intervalId);
  };

  const countHandler = () => {
    setTimeToNextAction((time) => (time > 0 ? time - 1000 : 0));
  };

  const actionHandler = async () => {
    setRunningAction(true);
    await action();
    setRunningAction(false);
  };

  const initiate = () => {
    setTimeToNextAction(timeMs);
    const timeout = setTimeout(() => {
      setTrigger(true);
      setTrigger(false);
    }, timeMs);
    setTimeoutId(timeout);
    const interval = setInterval(() => {
      countHandler();
    }, 1000);
    setIntervalId(interval);
    return { interval, timeout };
  };

  useEffect(() => {
    if (runningAction || !active) return cleanup();
    const { interval, timeout } = initiate();
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
      cleanup();
    };
    // eslint-disable-next-line
  }, [timeMs, runningAction, active]);

  useEffect(() => {
    if (trigger) actionHandler();
    // eslint-disable-next-line
  }, [trigger]);

  return {
    timeToNextAction,
    runningAction,
  };
};

export const useQuery = <T extends Record<string, string>>() => {
  const { search } = useLocation();
  const query = React.useMemo(() => {
    const params = new URLSearchParams(search);
    const parsed: Record<string, string> = {};
    params.forEach((v, k) => {
      parsed[k] = v;
    });
    console.log('parsing QP', parsed);
    return parsed as T;
  }, [search]);
  return query;
};
